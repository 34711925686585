import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SessionService } from './services/authentication/session.service';
import { AwsService } from './services/aws/aws.service';
import { AuthGuardService } from './guards/auth-guard.service';
import { LoginService } from './services/authentication/login.service';
import { RoleGuardService } from './guards/role-guard.service';
import { UserService } from './services/user/user.service';
import { ImageService } from './services/image/image.service';
import { ReferentialService } from './services/referentials/referential.service';
import { MapService } from './services/map/map.service';
import { HomeRedirectGuardService } from './guards/home-redirect-guard.service';
import { OneClickOnlyButtonDirective } from './directives/one-click-only-button/one-click-only-button.directive';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { ContributionsService } from './services/contributions/contributions.service';
import { TinymceComponent } from './components/tinymce/tinymce.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { LocalizationService } from './services/localization/localization.service';
import { WebsocketService } from './services/websocket/websocket.service';
import { MenuComponent } from './components/menu/menu.component';
import { RangePipe } from './pipes/range.pipe';

@NgModule({
  declarations: [
    TinymceComponent,
    MenuComponent,
    OneClickOnlyButtonDirective,
    RangePipe
  ],
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    LeafletModule,
    NgbDropdownModule,
    EditorModule
  ],
  exports: [
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgbDropdownModule,
    TinymceComponent,
    MenuComponent,
    RangePipe
  ],
  providers: [
    SessionService,
    AwsService,
    AuthGuardService,
    RoleGuardService,
    HomeRedirectGuardService,
    UserService,
    ImageService,
    ReferentialService,
    MapService,
    LoginService,
    ContributionsService,
    LocalizationService,
    WebsocketService,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class SharedModule {
}
