import { Injectable } from "@angular/core";
import { WebSocketSubject, webSocket } from "rxjs/webSocket";
import { environment } from "src/environments/environment";
import { TokenStorageService } from "../authentication/token-storage.service";
import { Observer } from "rxjs";
import { WebSocketMessage } from "src/app/model/websocket.model";

@Injectable()
export class WebsocketService {
    
    private websocket?: WebSocketSubject<any>;


    constructor(
        private tokenService: TokenStorageService
    ) {
        
    }

    private connectToWebsocket() {
        this.websocket = webSocket({
          url: `${environment.websocketUrl}?Auth=${this.tokenService.getAccessToken()}`,
          deserializer: (res) => res.data
        });
    }

    private getWebSocket() : WebSocketSubject<any> {
        if (!this.websocket) {
            this.connectToWebsocket();
        }
        return this.websocket;
    }

    subscribe(callback: Partial<Observer<any>> | ((data: any) => void)) {
        this.getWebSocket().subscribe(callback);
    }

    sendMessage(message: WebSocketMessage) {
        this.getWebSocket().next(message);
    }

    closeConnection() {
        if (this.websocket) {
            this.websocket.complete();
            this.websocket = undefined;
        }
    }
}