import { Component, OnInit, NgZone, OnDestroy, Signal} from '@angular/core';
import { SessionService } from '../../services/authentication/session.service';
import { UserService } from '../../services/user/user.service';
import { User } from '../../../model/user.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UserFormComponent } from '../../../admin/user-form/user-form.component'
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, concatMap, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalizationService } from '../../services/localization/localization.service';
import { WebsocketService } from '../../services/websocket/websocket.service';
import { WebSocketMessageType } from 'src/app/model/websocket.model';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  user: Signal<User|null>;
  isAdmin: boolean;
  isContributor: boolean;
  isReferencer: boolean;
  BASE_API = environment.apiUrl;
  loading = false;


  constructor(
    private sessionService: SessionService, 
    private userService: UserService, 
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private ngZone: NgZone,
    private http: HttpClient,
    private localizationService: LocalizationService,
    private webSocketService: WebsocketService
  ) {
  }

  ngOnInit() {
    if (sessionStorage.getItem("keepAlive") === null){
      this.router.navigate(['/account/login']);
    }
    sessionStorage.setItem("keepAlive", "true");
    this.user = this.sessionService.user;
    this.isAdmin = this.userService.isAdmin();
    this.isContributor = this.userService.isContributor();
    this.isReferencer = this.userService.isReferencer();
    this.webSocketService.subscribe({
      next: (msg) => {
        const message = JSON.parse(msg) as WebSocketMessageType;
        if (message.type === 'user' && message.properties.user.username === this.user().username) {
          switch(message.properties.context) {
            case 'delete':
              this.router.navigate(['/account/logout'])
              break;
            case 'update':
              this.sessionService.storeUserInfo(message.properties.user)
              break;
            case 'create':
            default:
              console.error("websocket say that your account has been create while you were connected, this should not appear in theory")
              break;
          }
         
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.webSocketService.closeConnection()
  }

  edit(u: User) {
    const formUser = this.formBuilder.group({
      username: u.username,
      name: u.name,
      firstname: u.firstname,
      picture: u.picture,
      deletePicture: false,
      email: u.email,
      roles: [u.roles],
      lang: this.localizationService.getLang(),
      enabled: true
    });
    const modalUser = this.modalService.open(UserFormComponent);
    //enable email, set email and disable email
    modalUser.componentInstance.emailDisable = false;
    modalUser.componentInstance.email = u.email;
    modalUser.componentInstance.emailDisable = true;
    modalUser.componentInstance.formUser = formUser;
    modalUser.componentInstance.action = 'SELF_EDIT';
    modalUser.componentInstance.userEdit = true;
    modalUser.componentInstance.hasApic = u.picture !== "";
    modalUser.result.then(
      (data: any) => {
        this.loading=true;
        this.editUser(data.user, data.pictureData, data.pictureDataBase64, data.deletePicture);
      },
      () => {}
    );
  }
  
  editUser(user: User, pictureData: ArrayBuffer = null, pictureDataBase64: string = null, deletePicture: boolean = false) {
    this.editMyself(user, pictureData, deletePicture).subscribe({
      next: (userData) => {
        if(deletePicture === false && pictureDataBase64 !== null) {
          userData.picture = pictureDataBase64;
        }
        userData.email = this.sessionService.getUserInfo().email;
        this.sessionService.storeUserInfo(userData);
        this.localizationService.loadFromStorage()
        this.loading = false;
      },
      error: (err) => {
        this.ngZone.run(() => console.error(err))
      },
    });
  }

  private editMyself(user: User, pictureData: ArrayBuffer = null, deletePicture: boolean = false): Observable<User> {
    let body: any = user;
    body.requestPictureUrl = Boolean(pictureData);
    body.deletePicture = deletePicture;

    const o1: Observable<any> =  this.http.put<any>(`${this.BASE_API}/api/admin/user/` +  encodeURIComponent(user.username), body);
    const o2: Observable<any> = pictureData
      ? o1.pipe(
          concatMap((res) => {
            if (res.uploadUrl) {
              return this.http
                .put<any>(res.uploadUrl, pictureData, {
                  headers: { "Content-Type": "image/*" },
                })
                .pipe(map(() => res));
            } else return res;
          })
        )
      : o1;
    const o3 = o2.pipe(concatMap((userUpdated) => 
      this.http.get<User>(`${this.BASE_API}/user/me/`).pipe(map((rep) => rep)))
    )
    return o3.pipe(map((linkToPic) => linkToPic));
  }

}
