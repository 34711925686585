import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {TokenStorageService} from '../../shared/services/authentication/token-storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  readonly doNotInterceptDomains = [
    "api-adresse.data.gouv.fr",
    "mt2.google.com",
    "s3.eu-west-1.amazonaws.com",
    "X-Amz-Security-Token",
  ];

  constructor(private tokenStorageService: TokenStorageService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.tokenStorageService.getAccessToken();

    for (const domain of this.doNotInterceptDomains) {
      if (request.url.includes(domain)) {
        return next.handle(request);
      }
    }

    if (accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    return next.handle(request);
  }
}
