import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Contribution } from 'src/app/model/contrib.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContributionsService {

  private BASE_API = environment.apiUrl;

  constructor(private http: HttpClient) { }

  createContributions(contributions: Contribution[]): Observable<Contribution[]> {
    return this.http.post<Contribution[]>(`${this.BASE_API}/api/contributions`, contributions);
  }

  getNext(): Observable<Contribution> {
    const contributions = [
      new Contribution(129846,90962,18),
      new Contribution(129846,90963,18),
      new Contribution(129841,90966,18),
      new Contribution(129843,90960,18),
      new Contribution(259693,181923,19),
      new Contribution(259692,181922,19)
    ];
    return of(contributions[Math.floor(Math.random() * contributions.length)]);
    // TODO implement
    //return this.http.get<Contribution>(`${this.BASE_API}/api/contributions`);
  }
}
