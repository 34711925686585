import { Layer } from 'leaflet';

export class LeafletLayers {

    constructor(
        public baseLayers: leafletLayerType[],
        public baseLayer: string,
        public overlayLayers: leafletLayerType[] = []
    ) { }

}

export type leafletLayerType = {
    id: string, 
    name: string, 
    enabled: boolean, 
    layer: Layer
}