import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HomeRedirectGuardService } from './shared/guards/home-redirect-guard.service';
import { SharedModule } from './shared/shared.module';

export const routes: Routes = [
  { path: '', redirectTo: '/account/login', pathMatch: 'full' },
  { path: 'home', children: [], pathMatch: 'full', canActivate: [HomeRedirectGuardService] },
  { path: 'account', loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule)},
  {
    path: 'referencer',
    loadChildren: () => import('./referencer/referencer.module').then(mod => mod.ReferencerModule),
    pathMatch: 'prefix'
  },
  {
    path: 'contributor',
    loadChildren: () => import('./contributor/contributor.module').then(mod => mod.ContributorModule),
    pathMatch: 'prefix'
  },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule), pathMatch: 'full'},
  { path: 'error', loadChildren: () => import('./error/error.module').then(mod => mod.ErrorModule), pathMatch: 'prefix'},
  { path: '**', redirectTo: 'error/404'}
];

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
