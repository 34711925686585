<div class="modal-header justify-content-between">
  <div class="ptft-title">
      <span class="material-icons">person_add</span>
    <h4 *ngIf="action === 'EDIT'; else alternateTitle" class="modal-title">{{ 'ADMIN.USER.USER_FORM.ALTERNATE_TITLE' | translate }}</h4>
    <ng-template #alternateTitle><h4 class="modal-title">{{ 'ADMIN.USER.USER_FORM.TITLE' | translate }}</h4></ng-template>
  </div>
  <button type="button" class="close btn btn-light mr-auto" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form id="user-edition-form" [formGroup]="formUser" (ngSubmit)="close()">
  <div class="modal-body">
      <div *ngIf="formUser.controls.username.hasError('lowercase') && (formUser.touched || formUser.dirty)" class="alert alert-danger">
        {{ 'ADMIN.USER.VALIDATOR.USERNAME.LOWERCASE' | translate }}
      </div>
      <div class="top user-content">  
        <div class="form-group row">
          <label for="username-input" class="col-sm-3 col-form-label field">{{ 'ADMIN.USER.LOGIN' | translate }}</label>
          <div class="col-sm-9">
            <input id="username-input" type="text" class="form-control" formControlName="username" required>
          </div>
        </div>
        <div class="form-group row">
          <label for="name-input" class="col-sm-3 col-form-label field">{{ 'ADMIN.USER.NAME' | translate }}</label>
          <div class="col-sm-9">
            <input id="name-input" type="text" class="form-control" formControlName="name" required>
          </div>
        </div>
        <div class="form-group row">
          <label for="firstname-input" class="col-sm-3 col-form-label field">{{ 'ADMIN.USER.FIRSTNAME' | translate }}</label>
          <div class="col-sm-9">
            <input id="firstname-input" type="text" class="form-control" formControlName="firstname" required>
          </div>
        </div>
        <div class="form-group row">
          <label for="picture-input" class="col-sm-3 col-form-label field">{{ 'ADMIN.USER.PICTURE' | translate }}</label>
          <div class="col-sm-9">
            <ngb-alert *ngIf='error' [dismissible]="false" type="danger" (close)="closeAlert()"><span id="image-failure-message">{{ 'ADMIN.USER.ERROR_PICTURE' | translate }}</span></ngb-alert>
            <input id="picture-input" [type]="pictureDelete ? 'hidden' : 'file'" accept="image/x-png,image/jpeg" class="form-control" formControlName="picture" (change)="onFileChange($event)">
            <label for="picture-input" *ngIf="!pictureDelete">{{ 'ADMIN.USER.PICTURE_SIZE' | translate }}</label>
          </div>
          <!-- Toggle to delete picture.-->
          <div class="col-sm-9 offset-sm-3">
            <input id="delete-picture-input" type="checkbox" class="tgl tgl-skewed form-control" formControlName="deletePicture" (change)="deletePictureState($event)">
            <label class="tgl-btn" [attr.data-tg-off]="'ADMIN.USER.KEEP_PICTURE' | translate" [attr.data-tg-on]="'ADMIN.USER.DELETE_PICTURE' | translate" for="delete-picture-input">&emsp;</label>
          </div>
        </div>
        <div class="form-group row">
          <label for="email-input" class="col-sm-3 col-form-label field">{{ 'ADMIN.USER.EMAIL' | translate }}</label>
          <div class="col-sm-9">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text">&#64;</div>
              </div>
              <input id="email-input" type="text" class="form-control" formControlName="email"  required>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <span class="col-sm-3 col-form-label">{{ 'ADMIN.USER.LANGUAGE' | translate }}</span>
          <div class="col-sm-9">
            <div *ngFor="let lang of localizationService.getSupportedLangs()">
              <input type="radio" [value]="lang" [id]="lang" [checked]="localizationService.getLang() === lang" formControlName="lang">
              <label [for]="lang" class="ps-2">{{ 'LANGUAGE.LANGUAGES.' + lang | translate }}</label>
            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf="!isSelf">
          <label for="roles-select" class="col-sm-3 col-form-label field">{{ 'ADMIN.USER.ROLE' | translate }}</label>
          <div class="col-sm-9">
            <div class="form-group">
              <input type="hidden" formControlName="roles" tabindex="-1">
              <div *ngFor="let role of profiles" class="form-check form-switch">
                <input type="checkbox" [value]="role" [id]="role" class="form-check-input" (change)="keepSelected(role)" [checked]="isRole(role)" [disabled]="isDisabled(role)">
                <label class="form-check-label" [for]="role">{{ 'ADMIN.USER.ROLES.' + role | translate }}</label>
              </div>              
            </div>
          </div>      
        </div>
        <div class="form-group row center" *ngIf="!isSelf">
          <p class="col-sm-3 mb-0">{{ 'ADMIN.USER.ENABLED' | translate }}</p>
          <div class="col-sm-9">
            <input id="enabled-input" type="checkbox" class="tgl tgl-skewed" formControlName="enabled" (click)="state()">
            <label class="tgl-btn" [attr.data-tg-off]="'ADMIN.USER.DISABLED' | translate | uppercase" [attr.data-tg-on]="'ADMIN.USER.ENABLED' | translate | uppercase" for="enabled-input">&emsp;</label>
          </div>
        </div>
      </div>
  </div>
  <div class="modal-footer">
    <a class="btn btn-outline-secondary" (click)="activeModal.dismiss()">{{ 'ADMIN.USER.USER_FORM.CANCEL' | translate }}</a>
    <button id="confirm-user-button" class="btn btn-primary" [disabled]="formUser.invalid" type="submit" (click)="activate()">{{ 'ADMIN.USER.USER_FORM.' + action | translate }}</button>
  </div>
</form>
