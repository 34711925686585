import { Injectable } from '@angular/core';

const ACCESS_TOKEN_ID = 'access_token';
const REFRESH_TOKEN_ID = 'refresh_token';
const ACCESS_TOKEN_EXPIRATION = 'access_token_expiration';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  private storage: Storage = localStorage;

  setAccessToken(value: string) {
    this.storage.setItem(ACCESS_TOKEN_ID, value);
  }

  setRefreshToken(value: string) {
    this.storage.setItem(REFRESH_TOKEN_ID, value);
  }

  setAccessTokenExpiration(date: number) {
    this.storage.setItem(ACCESS_TOKEN_EXPIRATION, date.toFixed(0))
  }

  getAccessToken(): string {
    return this.storage.getItem(ACCESS_TOKEN_ID);
  }

  getRefreshToken(): string {
    return this.storage.getItem(REFRESH_TOKEN_ID);
  }

  getAccessTokenExpiration(): number {
    return Number(this.storage.getItem(ACCESS_TOKEN_EXPIRATION))
  }
}
